
import {
    GET_SELLERS,
    GET_SELLERS_SUCCESS,
    ADD_NEW_SELLER,
    ADD_NEW_SELLER_SUCCESS,
    DELETE_SELLER_REQUEST,
    DELETE_SELLER_SUCCESS,
    DELETE_SELLER_FAIL
} from '../constants/sellers';

const initState = {
    loading: false,
    sellers: null,
    count: null
}


const deleteSeller = (seller, sellerIdToDelete) => {
    const newCategory = [...seller]
    const id = newCategory.map(el => el.id).indexOf(sellerIdToDelete)
    newCategory.splice(id, 1)
    return newCategory
}

const sellers = (state = initState, action) => {
    switch (action.type) {
        case GET_SELLERS:
            return {
                ...state,
                loading: true
            }
        case GET_SELLERS_SUCCESS:
            console.log("fff", action.payload.meta?.filter_count);
            return {
                ...state,
                loading: false,
                sellers: action.payload.data,
                count: action.payload.meta?.filter_count
            }
        case ADD_NEW_SELLER:
            return {
                ...state,
                loading: true
            }
        case ADD_NEW_SELLER_SUCCESS:
            return {
                ...state,
                loading: false,
                sellers: [action.payload, ...state.sellers]
            }
        case DELETE_SELLER_REQUEST:
            return { ...state, loading: true }
        case DELETE_SELLER_SUCCESS:
            return { loading: false, deleted: true, sellers: deleteSeller(state.sellers, action.id) }
        case DELETE_SELLER_FAIL:
            return { loading: false, deleted: false }
        default:
            return state;
    }
}

export default sellers